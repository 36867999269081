<template>
  <v-dialog
    v-model="dialog"
    persistent
    transition="dialog-bottom-transition"
    max-width="600"
  >
    <!--begin::Modal dialog-->
    <div class="bg-white poppins">
      <!--begin::Modal header-->
      <div class="modal-header py-4 align-center">
        <h4 class="mb-0 font-weight-bolder">{{ headerText }}</h4>
        <!--begin::Close-->
        <div
          class="btn btn-sm btn-icon btn-active-light-info"
          @click="toggleModal"
        >
          <span class="svg-icon">
            <v-icon size="22">mdi-close</v-icon>
          </span>
        </div>
        <!--end::Close-->
      </div>
      <!--end::Modal header-->
      <!--begin::Modal body-->
      <div class="modal-body d-flex flex-column max-h-700px scroll-y mx-5 py-7">
        <form class="row">
          <div class="col-12 h-75px" v-if="!this.isClient">
            <v-autocomplete
              v-model="formData.customer_id"
              label="Customer"
              :items="serverData.customers"
              item-text="text"
              item-value="index"
              dense
              outlined
              clearable
              :error-messages="customer_idErrors"
              @blur="$v.formData.customer_id.$touch()"
            />
          </div>
        </form>

        <!--begin::Actions-->
        <div class="mb-4 h-75px d-flex flex-grow-1 align-end justify-center">
          <button
            type="reset"
            class="btn btn-light mr-3 px-5 py-3 ls1"
            @click="resetFormData"
          >
            Clear
          </button>
          <button
            type="submit"
            class="btn btn-info px-5 py-3 ls1"
            @click="submitForm"
          >
            {{ submitButtonText }}
          </button>
        </div>
        <!--end::Actions-->
      </div>
      <!--end::Modal body-->
    </div>
    <!--end::Modal dialog-->
  </v-dialog>
</template>

<script>
import ApiService from "@/core/services/api.service";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import swalEdited from "@/core/services/AlertServices/SwalEdited";
import swalAdded from "@/core/services/AlertServices/SwalAdded";
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";

export default {
  name: "ActionsDialog",
  mixins: [validationMixin],
  props: ["refresher", "pageLoader"],
  validations: {
    formData: {
      // customer_id: {
      //   required: requiredIf(function () {
      //     return !this.isClient;
      //   }),
      // },
      customer_id: { required },
    },
  },
  data: () => ({
    dialog: false,
    serverData: null,
    formData: {
      customer_id: null,
      // container_number: null,
    },
  }),
  methods: {
    toggleModal() {
      if (this.dialog) {
        this.resetFormData();
      } else {
        this.loadDataFromServer();
      }
      this.dialog = !this.dialog;
    },
    loadDataFromServer() {
      // if (this.actionType) {
      //   let sendData = { id: this.$store.getters.getItemForAction.id };
      //   this.pageLoader(true);
      //   ApiService.post("/wes/wesBflSync/edit", sendData)
      //     .then(({ data }) => {
      //       this.serverData = data;
      //       this.formData = data.data;
      //       this.pageLoader(false);
      //     })
      //     .catch(() => {
      //       this.dialog = false;
      //       this.pageLoader(false);
      //     });
      // } else {
      this.pageLoader(true);
      ApiService.post("/wes/wesBflSync/create")
        .then(({ data }) => {
          this.serverData = data;
          this.pageLoader(false);
        })
        .catch(() => {
          this.dialog = false;
          this.pageLoader(false);
        });
      // }
    },
    resetFormData() {
      this.formData = {
        customer_id: null,
        // container_number: null,
      };
    },

    handleFormValidation(fieldName) {
      const errors = [];
      if (!this.$v.formData[fieldName].$dirty) return errors;
      if ("required" in this.$v.formData[fieldName]) {
        !this.$v.formData[fieldName].required &&
          errors.push("This field is required");
      }
      return errors;
    },

    submitForm() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      this.$store.commit(SET_PAGE_LOADING, true);

      // let sendData = {};
      //
      // if (!this.isClient) sendData.customer_id = this.formData.customer_id;

      let actionUrl = "store";
      if (this.actionType) actionUrl = "update";
      ApiService.post(`/wes/wesBflSync/${actionUrl}`, {
        ...this.formData,
      }).then(() => {
        if (this.actionType) swalEdited();
        else swalAdded();
        this.refresher();
        this.toggleModal();
      });
      // .finally(() => {
      //   this.$store.commit(SET_PAGE_LOADING, false);
      // });
    },
  },
  computed: {
    // if true, we are editing item, false we are adding
    actionType: function () {
      return !!this.$store.getters.getItemForAction;
    },
    submitButtonText: function () {
      if (this.actionType) return "Update";
      else return "Submit";
    },
    headerText: function () {
      // if (this.actionType) return "Edit Item";
      // else return "Add New Item";
      return "Refresh master data";
    },
    isClient() {
      return this.$store.getters.currentUser.data.is_client;
    },

    customer_idErrors: function () {
      return this.handleFormValidation("customer_id");
    },
    // container_numberErrors: function () {
    //   return this.handleFormValidation("container_number");
    // },
  },
};
</script>
